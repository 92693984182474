<template>
  <nav
    class="flex fixed md:relative md:h-screen md:flex-col  justify-between w-full md:p-8 xl:p-16 h-16 bg-gray-100 md:bg-gray-100 text-black  z-10"
  >
    <div class="flex flex-row w-full justify-center items-center md:hidden">
      <button
        class="mr-2 absolute left-0 pl-5"
        aria-label="Open Menu"
        @click="drawer"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          ></path>
        </svg>
      </button>
      <router-link to="/home">
        <!-- <img src="@/assets/logo.png" alt="Logo" class="mx-auto h-auto w-24" /> -->
        <div class="font-bold text-2xl">KG</div>
      </router-link>
    </div>

    <transition
      enter-class="opacity-0"
      enter-active-class="ease-out transition-medium"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-to-class="opacity-0"
    >
      <div
        v-show="isOpen"
        class="z-10 fixed inset-0 transition-opacity"
        @keydown.esc="isOpen = false"
      >
        <div
          class="absolute inset-0 bg-black opacity-50"
          tabindex="0"
          @click="isOpen = false"
        ></div>
      </div>
    </transition>

    <aside
      class="transform top-0 left-0 w-64 flex flex-col   justify-between md:w-full bg-gray-100 fixed md:relative p-8 md:p-0 h-full overflow-auto md:translate-x-0 ease-in-out transition-all duration-300 z-30"
      :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
    >
      <div class="flex w-full items-center justify-center">
        <router-link to="/home">
          <!-- <img class="px-8 object-contain" src="@/assets/logo.png" alt="" /> -->
          <div class="uppercase font-bold text-6xl">KG</div>
        </router-link>
      </div>
      <div class="heading-5 text-left grid gap-6">
        <router-link to="/home">home</router-link>
        <span disabled
          >experiences<sup class=" ml-1 px-2 rounded-full text-white bg-black"
            >coming</sup
          ></span
        >
        <span disabled
          >resume<sup class=" ml-1 px-2 rounded-full text-white bg-black"
            >coming</sup
          ></span
        >
        <!-- <router-link to="/experience">experiences</router-link> -->
        <!-- <router-link to="/resume">resume</router-link> -->
      </div>
      <div class="flex flex-col">
        <a
          href="mailto:kgeorge8155@gmail.com"
          class="btn bg-black text-white mb-2"
          >contact me</a
        >

        <span class="text-sm">proudly made in Toronto.</span>
      </div>
    </aside>
  </nav>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isOpen: false
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      }
    },
    "$route.path": {
      immediate: true,
      handler() {
        this.isOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", e => {
      if (e.keyCode === 27 && this.isOpen) this.isOpen = false;
    });
  },
  methods: {
    ...mapActions("user", ["logout"]),
    drawer() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style>
.router-link-active {
  @apply font-black;
}
</style>
