<template>
  <!-- With Nav -->
  <div id="app" class="grid " :class="{ 'md:grid-cols-5': nav }">
    <router-view name="nav" />
    <router-view
      class="min-h-screen "
      :class="{
        'md:col-span-4 overflow-y-scroll max-h-screen pt-20 md:pt-16 md:p-16  md:mt-0': nav
      }"
    />
    <!-- Without Nav -->
    <!-- <div id="app" class="grid">
    <router-view
      class="min-h-screen col-span-5 overflow-y-scroll max-h-screen pt-20 md:pt-16 md:p-16  md:mt-0"
    ></router-view> -->
  </div>
</template>

<script>
export default {
  computed: {
    nav() {
      return this.$route.path !== "/register";
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,400&display=swap");

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
