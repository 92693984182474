import Vue from "vue";
import VueRouter from "vue-router";
import Navbar from "../views/Navbar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "*",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    components: { default: () => import("../views/Home.vue"), nav: Navbar }
  }
  // {
  //   path: "/experience",
  //   name: "Experience",
  //   components: {
  //     default: () => import("../views/Experience.vue"),
  //     nav: Navbar
  //   }
  // },
  // {
  //   path: "/resume",
  //   name: "Resume",
  //   components: { default: () => import("../views/Resume.vue"), nav: Navbar }
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
